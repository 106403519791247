/* This package modifies React PropTypes to include type info generation, as a
 * replacement for react-docgen for use in dynamic type composition environments.
 *
 * The type info was written for @storybook/addon-docs v6.1.14.
 * - Lib/convert/proptypes/convert.js shows docgen typeinfo properties `name`, `raw`, `computed` and `value` are in use
 * - lib/docgen/createPropDef.js shows type made with `createSummaryValue(type.name)`
 * - lib/utils.js shows that returns `{ summary: type.name, detail: null }`.
 *
 * Other info was inferred from react-docgen v5.4.0-alpha0.
 * - src/types.js contains the PropTypeDescriptor in Flow
 * - src/utils/getPropType.js computes the initial type descriptor for a prop based on AST content.
 *
 * This package also adds additional types used by La Javaness. */
import { getFunctionName, isFunction, isNil, isObject, isPrimitiveLiteral, isString } from '@ljn/utils'

/* When in production, we expose the production Facebook PropTypes package
 * which only contains shims and doesn't perform typechecking. When in development,
 * we use the version that always typechecks so that our own callees can use our
 * library to typecheck in their development process. This library builds both
 * production and development builds and loads up the one corresponding to the
 * callee's NODE_ENV. */
const isProduction = process.env.NODE_ENV === 'production'
// eslint-disable-next-line import/no-dynamic-require
const PropTypes = require(isProduction ? 'prop-types' : 'prop-types/prop-types')

/* Copied from React PropTypes because they do not export createChainableTypeChecker.
 * See https://github.com/facebook/prop-types/issues/149. */
function PropTypeError(message) {
	this.message = message
	this.stack = ''
}
PropTypeError.prototype = Error.prototype

if (isProduction) {
	// Use this shim for your typechecker if your typechecker can be used directly,
	// and is not a function.
	const shim = () => {}
	shim.isRequired = shim

	// Use this shim if your typechecker is a function that takes parameters.
	const getShim = () => shim

	PropTypes.breakpointOf = getShim
	PropTypes.oneOfEnum = getShim
	PropTypes.oneOrSeveral = getShim
	PropTypes.allowedWith = getShim
	PropTypes.allowedIf = getShim
	PropTypes.requiredWith = getShim
	PropTypes.requiredIf = getShim
	PropTypes.ref = getShim
	PropTypes.stringOrNumber = shim
	PropTypes.elementOfTag = getShim
} else {
	/* We preserve original typecheckers so we can call them in our docgen wrapper. */
	const originalTypes = {
		...PropTypes,
	}

	/**
	 * Checks that a required prop is defined.
	 * @param   {object} props         Props being typechecked.
	 * @param   {string} propName      The name of the prop being checked (a key in `props`).
	 * @param   {string} componentName The name of the component, used for error messages.
	 * @param   {string} location      What is being checked, typically hardcoded to 'prop'.
	 * @returns {?Error}               A PropTypeError if the required prop is missing, else null.
	 */
	const validateRequired = (props, propName, componentName, location) => {
		if (props[propName] == null) {
			if (props[propName] === null) {
				return new PropTypeError(
					`The ${location} \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`null\`.`
				)
			}
			return new PropTypeError(
				`The ${location} \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`undefined\`.`
			)
		}
		return null
	}

	/**
	 * Wraps a type checker with logic to call a docgen function, including the
	 * isRequired extension added by PropTypes. The resulting wrapped function should
	 * remain chainable.
	 * @param   {string}   typeName           The name of the type checker being wrapped.
	 * @param   {Function} typeDocgenFunction The function capable of generating doc for this type.
	 */
	const ljnTypeDocgenWrapper = (typeName, typeDocgenFunction) => {
		PropTypes[typeName] = (props, propName, componentName, location, ...args) => {
			typeDocgenFunction(props, propName, typeName, false, args)
			originalTypes[typeName](props, propName, componentName, location, ...args)
		}

		PropTypes[typeName].isRequired = (props, propName, componentName, location, ...args) => {
			typeDocgenFunction(props, propName, typeName, true, args)
			return (
				validateRequired(props, propName, componentName, location) ||
				originalTypes[typeName](props, propName, componentName, location, ...args)
			)
		}
	}

	/**
	 * Wraps a type checking function with logic to call a docgen function, including the
	 * isRequired extension added by PropTypes. The resulting wrapped function should
	 * remain chainable.
	 *
	 * Differs from ljnTypeDocgenWrapper in that it passes the type checker's parameters
	 * to the docgen function, which is required for checkers like oneOf or instanceOf.
	 * @param   {string}   typeName           The name of the type checker being wrapped.
	 * @param   {Function} typeDocgenFunction The function capable of generating doc for this type.
	 */
	const ljnFunctionDocgenWrapper = (typeName, typeDocgenFunction) => {
		PropTypes[typeName] = (...runtimeParams) => {
			const validate = (props, propName, componentName, location, ...args) => {
				typeDocgenFunction(props, propName, typeName, false, runtimeParams, args)
				originalTypes[typeName](...runtimeParams)(props, propName, componentName, location, ...args)
			}
			validate.isRequired = (props, propName, componentName, location, ...args) => {
				typeDocgenFunction(props, propName, typeName, true, runtimeParams, args)
				return (
					validateRequired(props, propName, componentName, location) ||
					originalTypes[typeName](...runtimeParams)(props, propName, componentName, location, ...args)
				)
			}
			return validate
		}
	}

	/**
	 * Adds docgen information for primitive types.
	 * @param {object}  props      Props of the instance being typechecked.
	 * @param {string}  propName   The name of the prop for which to add type info.
	 * @param {string}  typeName   The simple prop type (as defined by react-docgen).
	 * @param {boolean} isRequired Whether the prop is required by its component.
	 */
	const ljnSimpleTypeDocgen = (props, propName, typeName, isRequired) => {
		if (global && global.reclame && global.reclame.docgen) {
			const type = {
				name: typeName,
				raw: null,
				required: isRequired,
				computed: false,
				value: null,
			}

			global.reclame.docgen.types[propName] = type
		}
	}

	/* In sync with react-docgen 5.4.0-alpha0. We use ljnSimpleTypeDocgen for all these. */
	const simplePropTypes = [
		'array',
		'bool',
		'func',
		'number',
		'object',
		'string',
		'any',
		'element',
		'node',
		'symbol',
		'elementType',
	]
	simplePropTypes.forEach((typeName) => ljnTypeDocgenWrapper(typeName, ljnSimpleTypeDocgen))

	const printValue = (value) => {
		if (isFunction(value)) {
			return getFunctionName(value)
		}
		if (isObject(value)) {
			return JSON.stringify(value, null, '\t')
		}
		if (isString(value)) {
			return `'${value}'`
		}

		return value.toString()
	}

	const getEnumValues = (values) => {
		return values.map((value) => ({
			value: printValue(value),
			computed: !isPrimitiveLiteral(value),
		}))
	}

	/**
	 * Adds docgen information for PropTypes.instanceOf.
	 * @param {object}  props         Props of the instance being typechecked.
	 * @param {string}  propName      The name of the prop for which to add type info.
	 * @param {string}  typeName      The simple prop type (as defined by react-docgen).
	 * @param {boolean} isRequired    Whether the prop is required by its component.
	 * @param {Array}   runtimeParams The parameters passed to PropTypes.instanceOf.
	 */
	const ljnInstanceTypeDocgen = (props, propName, typeName, isRequired, runtimeParams) => {
		if (global && global.reclame && global.reclame.docgen) {
			const expectedClassName = getFunctionName(runtimeParams[0])

			const type = {
				name: 'instanceof',
				raw: null,
				required: isRequired,
				computed: false,
				value: expectedClassName,
			}

			global.reclame.docgen.types[propName] = type
		}
	}
	ljnFunctionDocgenWrapper('instanceOf', ljnInstanceTypeDocgen)

	/**
	 * Adds docgen information for PropTypes.oneOf.
	 * @param {object}  props         Props of the instance being typechecked.
	 * @param {string}  propName      The name of the prop for which to add type info.
	 * @param {string}  typeName      The simple prop type (as defined by react-docgen).
	 * @param {boolean} isRequired    Whether the prop is required by its component.
	 * @param {Array}   runtimeParams The parameters passed to PropTypes.oneOf.
	 */
	const ljnOneOfDocgen = (props, propName, typeName, isRequired, runtimeParams) => {
		if (global && global.reclame && global.reclame.docgen) {
			const values = getEnumValues(runtimeParams[0])

			const type = {
				name: 'enum',
				raw: null,
				required: isRequired,
				computed: values.some((value) => value.computed),
				value: values,
			}

			global.reclame.docgen.types[propName] = type
		}
	}
	ljnFunctionDocgenWrapper('oneOf', ljnOneOfDocgen)

	/**
	 * Adds docgen information for types that have complex object-like values.
	 * @param {string}  docgenTypeName    The type name to use in the documentation.
	 * @param {boolean} preserveValueKeys Whether to return the values as an object instead of array.
	 * @returns {Function} The actual docgen function.
	 */
	const ljnObjectLikeDocgen = (docgenTypeName, preserveValueKeys) => {
		/**
		 * Adds docgen information for types that have complex object-like values.
		 * @param {object}  props         Props of the instance being typechecked.
		 * @param {string}  propName      The name of the prop for which to add type info.
		 * @param {string}  typeName      The simple prop type (as defined by react-docgen).
		 * @param {boolean} isRequired    Whether the prop is required by its component.
		 * @param {Array}   runtimeParams The parameters passed to PropTypes.exact.
		 */
		return (props, propName, typeName, isRequired, runtimeParams) => {
			if (global && global.reclame && global.reclame.docgen) {
				// We'll create fake props for the union children, and checkPropTypes to generate doc for them.
				const docgenChildren = {}
				Object.keys(runtimeParams[0]).forEach((key) => {
					docgenChildren[`${propName}:${key}`] = runtimeParams[0][key]
				})

				// Now we can checkPropTypes.
				PropTypes.checkPropTypes(docgenChildren, {}, 'prop', propName)

				// Then, we rearrange the obtained type info and clean up.
				let values
				if (preserveValueKeys) {
					values = {}
					Object.keys(docgenChildren).forEach((key) => {
						const typeInfo = global.reclame.docgen.types[key]
						delete global.reclame.docgen.types[key]
						values[key] = typeInfo
					})
				} else {
					values = Object.keys(docgenChildren).map((key) => {
						const typeInfo = global.reclame.docgen.types[key]
						delete global.reclame.docgen.types[key]
						return typeInfo
					})
				}

				const type = {
					name: docgenTypeName,
					raw: null,
					required: isRequired,
					computed: false,
					value: values,
				}

				global.reclame.docgen.types[propName] = type
			}
		}
	}
	ljnFunctionDocgenWrapper('oneOfType', ljnObjectLikeDocgen('union', false))
	ljnFunctionDocgenWrapper('exact', ljnObjectLikeDocgen('exact', true))
	ljnFunctionDocgenWrapper('shape', ljnObjectLikeDocgen('shape', true))

	/**
	 * Adds docgen information PropTypes with a unique subtype, like arrayOf and objectOf.
	 * @param   {string}   docgenTypeName The type name to use in the generated documentation.
	 * @returns {Function} A ljn type docgen function for one type name.
	 */
	const ljnSimpleSubtypeDocgen = (docgenTypeName) => {
		/**
		 * Adds docgen information PropTypes with a unique subtype, like arrayOf and objectOf.
		 * @param {object}  props         Props of the instance being typechecked.
		 * @param {string}  propName      The name of the prop for which to add type info.
		 * @param {string}  typeName      The simple prop type (as defined by react-docgen).
		 * @param {boolean} isRequired    Whether the prop is required by its component.
		 * @param {Array}   runtimeParams The parameters passed to the PropType.
		 */
		return (props, propName, typeName, isRequired, runtimeParams) => {
			if (global && global.reclame && global.reclame.docgen) {
				// We'll create fake props for the union children, and checkPropTypes to generate doc for them.
				const docgenChildren = { [`${propName}:content`]: runtimeParams[0] }

				// Now we can checkPropTypes.
				PropTypes.checkPropTypes(docgenChildren, {}, 'prop', propName)

				// Then, we rearrange the obtained type info and clean up.
				const values = Object.keys(docgenChildren).map((key) => {
					const typeInfo = global.reclame.docgen.types[key]
					delete global.reclame.docgen.types[key]
					return typeInfo
				})

				const type = {
					name: docgenTypeName,
					raw: null,
					required: isRequired,
					computed: false,
					value: values[0],
				}

				global.reclame.docgen.types[propName] = type
			}
		}
	}
	ljnFunctionDocgenWrapper('arrayOf', ljnSimpleSubtypeDocgen('arrayOf'))
	ljnFunctionDocgenWrapper('objectOf', ljnSimpleSubtypeDocgen('objectOf'))

	/**
	 * PropType that can be used in conjunction with the `useStateFromBreakpoint` to
	 * make a prop support breakpoint objects, as they are defined in `@xstyled`.
	 * @param {object} internalType The actual type of the prop.
	 * @returns {object}            A PropType that matches the internal type, or an
	 * object where keys are breakpoint names and values match the internal type.
	 */
	PropTypes.breakpointOf = (internalType) => PropTypes.oneOfType([internalType, PropTypes.objectOf(internalType)])

	/**
	 * A type to use when you want to delete a prop from your component.
	 * @type {symbol}
	 */
	PropTypes.deleted = Symbol('ReclameDeletedPropType')

	/**
	 * PropType that matches one possible value of an enum.
	 * @param   {object} theEnum The enum to match.
	 * @returns {object}         A PropType that matches one possible value of that enum.
	 */
	PropTypes.oneOfEnum = (theEnum) => PropTypes.oneOf(Object.values(theEnum))

	/**
	 * PropType that matches a type, or an array of elements of that type.
	 * @param   {Function} theType The PropType to match, eg. PropTypes.string.
	 * @returns {Function}         The type that matches a single instance or an array of `theType`.
	 */
	PropTypes.oneOrSeveral = (theType) => PropTypes.oneOfType([theType, PropTypes.arrayOf(theType)])

	/**
	 * PropType that validates a type, but only considers it valid when other props
	 * are also provided.
	 * @param  {Function}              propType   A PropTypes validator function applied
	 * to the prop receiving this PropType.
	 * @param  {Array.<string>|string} otherProps The name of the prop, or array of props,
	 * that are required for the type to be allowed.
	 * @returns {Function}            A PropType validator function running `propType`
	 * and checking that if the prop being checked is defined, then at least one of the
	 * `otherProps` props is also defined.
	 * @example
	 * // A user card displays a person's nickname, and allows specifying her real name too,
	 * // but only when the nickname is passed.
	 * Image.propTypes = {
	 *  realName: PropTypes.allowedWith(PropTypes.string, 'nickname'),
	 * }
	 */
	PropTypes.allowedWith = function allowedWith(propType, otherProps) {
		return function validate(props, propName, componentName, ...rest) {
			const allowedPool = Array.isArray(otherProps) ? otherProps : [otherProps]
			if (!isNil(props[propName]) && allowedPool.every((name) => isNil(props[name]))) {
				return new Error(
					`Prop ${propName} may only be used if one of these props is also provided: ${allowedPool.sort().join(', ')}.`
				)
			}

			return propType(props, propName, componentName, ...rest)
		}
	}

	/**
	 * PropType that validates a type, but only allows it to be defined when a
	 * condition is met.
	 * @param  {Function} propType          A PropTypes validator function applied
	 * to the prop receiving this PropType.
	 * @param  {Function} conditionFunction A function receiving the props, name of
	 * the prop being evaluated, and name of the component, and that must return
	 * `true` if the prop should be allowed, or `false` otherwise.
	 * @returns {Function}            A PropType validator function combining the
	 * constraints laid by `propType` and the conditional requirement based on the
	 * return value of `conditionFunction`.
	 * @example
	 * // onMouseOver is only allowed if mouseEvents is true, to help with debugging.
	 * Component.propTypes = {
	 *  mouseEvents: PropTypes.bool,
	 *  onMouseOver: PropTypes.allowedIf(PropTypes.func, (props) => props.mouseEvents),
	 * }
	 */
	PropTypes.allowedIf = function allowedIf(propType, conditionFunction) {
		return function validate(props, propName, componentName, ...rest) {
			if (!isNil(props[propName]) && !conditionFunction(props, propName, componentName)) {
				return new Error(
					`You may only use prop '${propName}' under certain conditions, which are not met in this instance. Check the exact conditions in your 'allowedIf' prop type declaration.`
				)
			}

			return propType(props, propName, componentName, ...rest)
		}
	}

	/**
	 * PropType that validates a type, but also ensures that either this prop, or one of
	 * those provided in the `otherProps` array, is present.
	 * @param  {Function}              propType   A PropTypes validator function applied
	 * to the prop receiving this PropType.
	 * @param  {Array.<string>|string} otherProps The name of the prop, or array of props,
	 * the presence of which is checked alongside the prop receiving this PropType.
	 * @returns {Function}            A PropType validator function combining the
	 * constraints laid by `propType` and the presence of at least one of the props.
	 * @example
	 * // alt is mandatory unless the image is explicitly propped as decorative.
	 * Image.propTypes = {
	 *  decorative: PropTypes.requiredWith(PropTypes.oneOf(true), 'alt'),
	 *  alt: PropTypes.requiredWith(PropTypes.string, 'decorative'),
	 * }
	 */
	PropTypes.requiredWith = (propType, otherProps) => {
		return function validate(props, propName, componentName, ...rest) {
			const requiredPool = Array.isArray(otherProps) ? [...otherProps, propName] : [otherProps, propName]
			if (requiredPool.every((name) => isNil(props[name]))) {
				return new Error(`You must provide either of these props: ${requiredPool.sort().join(', ')}.`)
			}

			return propType(props, propName, componentName, ...rest)
		}
	}

	/**
	 * PropType that validates a type, but also ensures that it is required when a
	 * condition is met.
	 * @param  {Function} propType          A PropTypes validator function applied
	 * to the prop receiving this PropType.
	 * @param  {Function} conditionFunction A function receiving the props, name of
	 * the prop being evaluated, and name of the component, and that must return
	 * `true` if the prop should be required, or `false` otherwise.
	 * @returns {Function}            A PropType validator function combining the
	 * constraints laid by `propType` and the conditional requirement based on the
	 * return value of `conditionFunction`.
	 * @example
	 * // ariaLabel is mandatory if no text content is provided.
	 * Component.propTypes = {
	 *  text: PropTypes.string,
	 *  ariaLabel: PropTypes.requiredIf(PropTypes.string, (props) => !(props.text?.length)),
	 * }
	 */
	PropTypes.requiredIf = (propType, conditionFunction) => {
		return function validate(props, propName, componentName, ...rest) {
			if (isNil(props[propName]) && conditionFunction(props, propName, componentName)) {
				return new Error(
					`You must provide prop '${propName}' under certain conditions, which are met in this instance. Check the exact conditions in your 'requiredIf' prop type declaration.`
				)
			}

			return propType(props, propName, componentName, ...rest)
		}
	}

	/**
	 * PropType that matches a React reference to something.
	 * @param   {object=} internalType The type of the thing being referenced, if set.
	 * @returns {object}  A PropType for a React reference.
	 */
	PropTypes.ref = (internalType = PropTypes.any) => {
		return PropTypes.shape({
			current: internalType,
		})
	}

	/**
	 * Shorthand PropType that matches a string or a number.
	 * @type {object}
	 */
	PropTypes.stringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

	/**
	 * PropType that matches a React element, and that the element's type, if readable,
	 * matches the expected type. This checker is permissive in environments where element
	 * types have been transformed by the transpiler, for example in MDX parsers.
	 * @param   {string} expectedTag The tag type to match on the root element.
	 * @returns {object} A PropType for a React element matching the expected tag.
	 */
	PropTypes.elementOfTag = (expectedTag) =>
		PropTypes.oneOfType([
			(props, propName, ...args) => {
				const elementCheck = PropTypes.element(props, propName, ...args)
				if (elementCheck) {
					return elementCheck
				}
				if (
					!isString(props[propName]) &&
					props[propName] &&
					isString(props[propName].type) &&
					props[propName].type.toLowerCase() !== expectedTag.toLowerCase()
				) {
					return new TypeError(
						`Invalid HTML element type. Expected a ${expectedTag}, received a ${props[propName].type}.`
					)
				}

				return undefined
			},
		])

	// NOTE: Don't forget to add a shim for your new typechecker to the isProduction branch!
}

export default PropTypes
